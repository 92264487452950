import {mapGetters} from "vuex";

export const headerFormsName = {
  computed: {
    ...mapGetters('sectionsAndForms', {getSectionsData: 'getSectionsData'}),
    currentSection() {
      const section = this.$route.path.split('/')[1].substr(-1)
      return Number(section)
    },
    currentPath() {
      return this.$route.path.split('/')[2]
    },
    currentPathIndex() {
      return this.getSectionsData?.find(el => el.id === this.currentSection)?.forms.findIndex(el => el.path === this.currentPath)
    },
    pageTitle() {
      return this.getSectionsData?.find(el => el.id === this.currentSection)?.forms.find(el => el.path === this.currentPath)?.title
    },
  },
  methods: {
    goNextStage() {
      this.$router.push('/' + this.getSectionsData?.find(el => el.id === this.currentSection).path + '/' + this.getSectionsData?.find(el => el.id === this.currentSection)?.forms[this.currentPathIndex + 1].path).then()
    },
    goPrevStage() {
      this.$router.push('/' + this.getSectionsData?.find(el => el.id === this.currentSection).path + '/' + this.getSectionsData?.find(el => el.id === this.currentSection)?.forms[this.currentPathIndex - 1].path).then()
    }
  },
}